<template>
    <v-app>
        <v-main :class="{'pl-0': !$vuetify.breakpoint.smAndDown}">
            <div id="content-start"></div>
            <router-view></router-view>
        </v-main>
    </v-app>
</template>

<script>

export default {
    name: 'App',
    data: () => ({
        //
    }),
    watch:{
        $route(){
            this.$vuetify.goTo('#app');
        }
    }
};
</script>
<style>
#app{
    width: 100%;
    height: 100%;
}
</style>
