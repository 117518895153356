<template>
  <div class="landing-page-wrapper">
    <div id="home"/>
    <!--HEADER-->
    <header class="header-holder">
      <div class="menu-wrapper relative center-relative">
        <div class="header-logo d-flex" @click="scrollToComponent('home')">
          <v-img src="@/assets/logo.png" class="logo"/>
          <h2>One Start</h2>
        </div>
        <div class="menu-icon">
          <v-btn icon dark large @click="drawer=true">
            <v-icon>mdi-menu</v-icon>
          </v-btn>
          <v-navigation-drawer
              v-model="drawer"
              color="#060606"
              app
              temporary
          >
            <ul class="main-menu">
              <li
                  v-for="({text, href, name}, mi) in menuItems"
                  :key="mi"
                  class="menu-item"
              >
                <a @click="goToPage(name)">
                  {{ text }}
                </a>
              </li>
            </ul>
          </v-navigation-drawer>
        </div>
        <div class="menu">
          <nav class="menu-container">
            <ul class="main-menu">
              <li
                  v-for="({text, href, name}, mi) in menuItems"
                  :key="mi"
                  class="menu-item"
              >
                <a @click="goToPage(name)">
                  {{ text }}
                </a>
              </li>
            </ul>
          </nav>
        </div>
        <div class="clear"></div>
      </div>
    </header>
    <!--CONTENT-->
    <div class="site-content">
      <!--FULL_HEIGHT_IMG-->
      <div class="first-section first-section-cover"
           :style="{ backgroundImage: 'url(\'' + require('@/assets/images/reimbursement-bg.jpg') + '\')' }">
        <div class="first-section-content mb-12">
          <div class="first-section-logo mx-auto">
            Amazon Owes You Money
          </div>
          <h3 class="first-section-light-text text-capitalize">Get reimbursements from Amazon</h3>
          <div class="chevron-down-wrapper hidden-sm-and-down">
            <v-btn
                class="chevron-down"
                icon
                @click="scrollToComponent('reimbursement', true)"
            >
              <v-icon
                  color="#ff4f5b"
                  size="96"
              >
                mdi-chevron-down
              </v-icon>
            </v-btn>
          </div>
        </div>
      </div>
      <!--REIMBURSEMENT-->
      <div id="reimbursement" class="section sixth-section">
        <div class="section-wrapper sixth-section-content mx-auto content-1170">
          <v-row>
            <v-col cols="12" md="6">
              <p class="section-title">Reimbursement</p>
              <p class="section-text">Get reimbursements from Amazon</p>
            </v-col>
            <v-col cols="12" md="6">
              <img
                  src="@/assets/images/reimbursement.png"
                  style="width: 100%"
                  alt=""/>
            </v-col>
          </v-row>
          <div class="section-detail mt-8">
            <v-row>
              <v-col cols="12" sm="12">
                <p class="section-text detail-p">
                  We help you get reimbursements from Amazon by analyzing your FBA inventory and
                  opening cases on your behalf for your lost and damaged items. Unlike other services,
                  ours is not an automated software. We open cases manually with your Dedicated
                  Account Manager and follow up until you are treated fairly. 100% TOS compliant is
                  guaranteed.
                </p>
              </v-col>
            </v-row>
            <v-row class="mt-8">
              <v-col cols="12" sm="3">
                <v-card flat class="card-rounded">
                  <v-card-text>
                    <v-img
                        class="mx-auto"
                        width="100"
                        height="100"
                        src="@/assets/images/icon1.png"
                    />
                  </v-card-text>
                  <v-card-text class="card-text">
                    Each account is assigned a Reimbursement Manager that submits your claims on
                    your behalf and keep track of your eligible refunds.
                  </v-card-text>
                </v-card>
              </v-col>
              <v-col cols="12" sm="3">
                <v-card flat class="card-rounded">
                  <v-card-text>
                    <v-img
                        class="mx-auto"
                        width="100"
                        height="100"
                        src="@/assets/images/icon2.png"
                    />
                  </v-card-text>
                  <v-card-text class="card-text">
                    We make money when you make money. There are no monthly fees or long term
                    commitments.
                  </v-card-text>
                </v-card>
              </v-col>
              <v-col cols="12" sm="3">
                <v-card flat class="card-rounded">
                  <v-card-text>
                    <v-img
                        class="mx-auto"
                        width="100"
                        height="100"
                        src="@/assets/images/icon3.png"
                    />
                  </v-card-text>
                  <v-card-text class="card-text">
                    As your account synced, our software goes to work. Our Reimbursement Manager
                    starts Manual case filing immediately.
                  </v-card-text>
                </v-card>
              </v-col>
              <v-col cols="12" sm="3">
                <v-card flat class="card-rounded">
                  <v-card-text>
                    <v-img
                        class="mx-auto"
                        width="100"
                        height="100"
                        src="@/assets/images/icon4.png"
                    />
                  </v-card-text>
                  <v-card-text class="card-text">
                    Unlike other services, OneStart Reimbursement is non-automated when submitting
                    cases and <br class="hidden-sm-and-down"> 100% TOS compliant. With us your
                    account is safe.
                  </v-card-text>
                </v-card>
              </v-col>
            </v-row>
            <v-row class="mt-8">
              <v-col>
                <h1 class="white--text mb-8">Services;</h1>
                <ul class="mt-5">
                  <li class="arrow-bullet">Accurate & Detailed Reports</li>
                  <li class="arrow-bullet">Manual Case Filing & Follow Up Until Reimbursement Earned
                  </li>
                  <li class="arrow-bullet">Amazon TOS Compliant Service You Can Trust</li>
                  <li class="arrow-bullet">Affordable, No-risk Pricing</li>
                  <li class="arrow-bullet">Dedicated Account Manager</li>
                </ul>
              </v-col>
            </v-row>
            <v-row class="mt-8">
              <v-col>
                <h1 class="white--text mb-8">Pricing;</h1>
                <p class="section-text detail-p mt-8">
                  <u>Straightforward Pricing:</u> You don't pay us until you get paid by Amazon. After
                  Amazon
                  credits your account, then we charge a 25% commission on the refunded amount.
                </p>
              </v-col>
            </v-row>
            <v-row class="mt-8">
              <v-col class="text-center">
                <v-btn
                    x-large
                    rounded
                    dark
                    @click="scrollToComponent('contact', true)"
                >
                  Start Now
                </v-btn>
              </v-col>
            </v-row>
          </div>
        </div>
      </div>
      <!--CONTACT-->
      <div id="contact" class="section seventh-section">
        <div class="section-wrapper seventh-section-content mx-auto content-1170">
          <v-row>
            <v-col cols="12" md="6" class="d-flex align-content-center">
              <div class="align-self-center">
                <p class="section-title mt-0">Contact</p>
                <p class="section-text mt-5">
                  <v-icon dark class="mr-3">mdi-map-marker</v-icon>
                  2242 Zumbehl Rd, Saint Charles, MO 63301
                </p>
                <p class="section-text">
                  <v-icon dark class="mr-4">mdi-email</v-icon>
                  <a href="mailto:info@onestartlabs.com">info@onestartlabs.com</a>
                </p>
              </div>
            </v-col>
            <v-col cols="12" md="6">
              <div class="outlined-container">
                <p class="section-text text-center">Contact Form</p>
                <v-form
                    v-model="formValid"
                    ref="landingContactForm"
                    @submit.prevent="contactRequest"
                    lazy-validation
                >
                  <v-text-field
                      id="contactName"
                      v-model="form.contactName"
                      label="Contact Name"
                      dark
                      color="white"
                      :rules="[requiredRule]"
                  />
                  <v-text-field
                      v-model="form.state"
                      label="State (Optional)"
                      dark
                      color="white"
                  />
                  <v-text-field
                      v-model="form.city"
                      label="City"
                      dark
                      color="white"
                      :rules="[requiredRule]"
                  />
                  <v-text-field
                      v-model="form.companyName"
                      label="Company (Amazon) Name"
                      dark
                      color="white"
                      :rules="[requiredRule]"
                  />
                  <v-text-field
                      v-model="form.email"
                      label="Contact Email"
                      dark
                      color="white"
                      :rules="[requiredRule]"
                  />
                  <v-text-field
                      v-model="form.couponCode"
                      label="Coupon Code (Optional)"
                      dark
                      color="white"
                  />
                  <v-textarea
                      v-model="form.message"
                      label="Message (Optional)"
                      dark
                      color="white"
                  />
                  <div class="text-right">
                    <v-btn
                        :disabled="sendFormSuccess || sendFormLoading"
                        :loading="sendFormLoading"
                        type="submit"
                        block
                        dark
                        color="#161616"
                    >
                      <template v-if="!sendFormSuccess">
                        Send
                      </template>
                      <template v-else>
                        <v-icon>mdi-check</v-icon>
                      </template>
                    </v-btn>
                  </div>
                </v-form>
              </div>
            </v-col>
          </v-row>
        </div>
      </div>
    </div>
    <!--FOOTER-->
    <footer class="footer-holder">
      <h4 class="text-center white--text">
        {{ new Date().getFullYear() }} — <span class="white--text">One Start LLC</span>
      </h4>
      <div class="text-center mt-3">
        <router-link :to="{name: 'PrivacyPolicies'}" target="_blank"
                     class="white--text text-decoration-underline">Privacy
          Policy
        </router-link>
        <span class="mx-3 white--text">-</span>
        <router-link :to="{name: 'CookiePolicies'}" target="_blank"
                     class="white--text text-decoration-underline">Cookie
          Policy
        </router-link>
      </div>
    </footer>
    <!--SNACKBAR-->
    <v-snackbar
        v-model="snackbar.model"
        :color="snackbar.color"
    >
      {{ snackbar.message }}
      <template v-slot:action="{ attrs }">
        <v-btn
            icon
            dark
            v-bind="attrs"
            @click="snackbar = false"
        >
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </template>
    </v-snackbar>
    <!--COOKIE ALERT-->
    <v-alert
        v-if="!cookiesAccepted"
        style="position: fixed; bottom: 15px; right: 15px; margin-left: 15px"
        dense
        class="mb-0"
        dark
    >
      <v-row align="center">
        <v-col class="py-0">
          This website uses cookies to ensure you get the best experience on our web site
          <router-link to="/cookie-policies" target="_blank">More info</router-link>
        </v-col>
        <div class="py-0 px-1">
          <v-btn
              outlined
              @click="acceptCookiePolicy"
          >
            Got it!
          </v-btn>
        </div>
      </v-row>
    </v-alert>
  </div>
</template>

<script>
import axios from "axios";

export default {
  name: "Reimbursement",
  data() {
    return {
      cookiesAccepted: JSON.parse(localStorage.getItem('cookies-accepted')),
      snackbar: {
        model: '',
        message: '',
        color: ''
      },
      requiredRule: v => !!v || 'This field is required',
      formValid: true,
      form: {
        contactName: '',
        state: '',
        city: '',
        companyName: '',
        email: '',
        couponCode: '',
        message: '',
      },
      sendFormLoading: false,
      sendFormSuccess: false,
      drawer: false,
      menuItems: [
        {text: 'Go to Home Page', href: 'home', name: 'Home', product: false, free: false},
      ],
    }
  },
  mounted() {
    this.$vuetify.goTo(`#home`, {duration: 0})
  },
  methods: {
    acceptCookiePolicy() {
      localStorage.setItem('cookies-accepted', 'true');
      this.cookiesAccepted = true;
    },
    goToPage(routeName) {
      this.$router.push({name: routeName})
    },
    scrollToComponent(href, focus) {
      this.$vuetify.goTo(`#${href}`, {duration: 1000})
      if (this.drawer) this.drawer = false;
      if (focus)
        document.getElementById('contactName').focus();
    },
    contactRequest() {
      if (this.$refs.landingContactForm.validate()) {
        this.sendFormLoading = true
        let payload = {
          name: this.form.contactName,
          email: this.form.email,
          subject: "[OneStart] Reimbursement Request/Contact (Reimbursement Page)",
          description: `${this.form.state || '-'} / ${this.form.city} --- ${this.form.companyName} --- ${this.form.couponCode} --- ${this.form.message}`
        }
        axios.post('https://prod-api.bookzpro.com/api/misc/reimbursement/request', payload)
            .then((res) => {
              if (res.data.success) {
                this.sendFormSuccess = true;
                this.showSnackbar('Your message has been received. We will contact with you as soon as possible.', 'success');
              } else {
                this.sendFormSuccess = false;
                let message = '';
                if (res.data?.apiError?.errorMessage)
                  message = res.data.apiError.errorMessage;
                else
                  message = 'An error occurred. Please try again later.';
                this.showSnackbar(message, 'error');
              }
            }).catch((error) => {
          this.sendFormSuccess = false;
          this.showSnackbar(error, 'error');
        }).then(() => {
          this.sendFormLoading = false;
        });
      }
    },
    showSnackbar(message, color) {
      this.snackbar.message = message;
      this.snackbar.color = color;
      this.snackbar.model = true;
    },
  },
  watch: {
    $route(value) {
      this.menuModel = this.menu.findIndex(m => m.path === value.name);
    },

    formValid(value) {
      if (!value) {
        setTimeout(() => {
          this.$refs.landingContactForm.resetValidation();
        }, 2000)
      }
    }
  }
}
</script>

<style scoped>
.header-holder {
  position: absolute;
  top: 0;
  background-color: transparent;
}

.menu {
  background-color: transparent;
}

.menu-item {
  transition: border-left-color .2s;
  border-left-color: white;
}

.menu-item:hover {
  border-left-color: #ff4f5b;
}

.first-section {
  min-height: 100vh;
}

.chevron-down-wrapper {
  width: 100%;
  left: 0;
  position: absolute;
  bottom: 72px;
  text-align: center;
}

.first-section-logo{
  font-size: 74px;
}

@media only screen and (min-width: 860px) {
  .first-section-content {
    width: 74vw;
  }
}

@media only screen and (max-width: 860px) {
  .first-section-content {
    width: 100vw;
  }
}
</style>