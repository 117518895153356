import Wrapper from "@/modules/imbursement/views/Home";
import CookiePolicies from "@/modules/imbursement/views/CookiePolicies";
import PrivacyPolicy from "@/modules/imbursement/views/PrivacyPolicy";
import Reimbursement from "@/modules/imbursement/views/Reimbursement";

const routes = [
    {
        path: '/',
        name: 'Home',
        component: Wrapper,
        meta: {
            title: '- Home'
        }
    },
    {
        path: '/reimbursement',
        name: 'Reimbursement',
        component: Reimbursement,
        meta: {
            title: ' - Reimbursement'
        }
    },
    {
        path: '/privacy-policies',
        name: 'PrivacyPolicies',
        component: PrivacyPolicy,
        meta: {
            title: ' - Privacy Policy'
        }
    },
    {
        path: '/cookie-policies',
        name: 'CookiePolicies',
        component: CookiePolicies,
        meta: {
            title: ' - Cookie Policies'
        }
    },
]

export default routes;
