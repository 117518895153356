<template>
    <div class="policy-container _secondary white--text">
        <div class="policy-wrapper">
            <div class="policy-heading">
                <h1 class="large-header">Cookie Policy</h1>
                <p class="grey--text text-center">Last updated: 02/18/2021</p>
            </div>
            <div class="policy-content">
                <p class="std-header">INTRODUCTION</p>
                <p class="std-text">One Start LLC (“we” or “us” or “our”) may use cookies, web beacons, tracking pixels,
                    and other tracking technologies when you visit our websites <strong>onestartlabs.com</strong>, <strong>bookzpro.com</strong>, including any
                    other media form, media channel, mobile website, or mobile application related or connected thereto
                    (collectively, the “Site”) to help customize the Site and improve your experience. </p>
                <p class="std-text">
                    We reserve the right to make changes to this Cookie Policy at any time and for any reason. We will
                    alert you about any changes by updating the “Last Updated” date of this Cookie Policy. Any changes
                    or modifications will be effective immediately upon posting the updated Cookie Policy on the Site,
                    and you waive the right to receive specific notice of each such change or modification.
                </p>
                <p class="std-text">
                    You are encouraged to periodically review this Cookie Policy to stay informed of updates. You will
                    be deemed to have been made aware of, will be subject to, and will be deemed to have accepted the
                    changes in any revised Cookie Policy by your continued use of the Site after the date such revised
                    Cookie Policy is posted.
                </p>
                <p class="std-header">USE OF COOKIES</p>
                <p class="std-text">
                    A “cookie” is a string of information which assigns you a unique identifier that we store on your
                    computer. Your browser then provides that unique identifier to use each time you submit a query to
                    the Site. We use cookies on the Site to, among other things, keep track of services you have used,
                    record registration information, record your user preferences, keep you logged into the Site,
                    facilitate purchase procedures, and track the pages you visit. Cookies help us understand how the
                    Site is being used and improve your user experience.
                </p>
                <p class="std-header">TYPES OF COOKIES</p>
                <p class="std-text">
                    The following types of cookies may be used when you visit the Site:
                </p>
                <p class="std-sub-header">
                    Advertising Cookies
                </p>
                <p class="std-text">
                    Advertising cookies are placed on your computer by advertisers and ad servers in order to display
                    advertisements that are most likely to be of interest to you. These cookies allow advertisers and ad
                    servers to gather information about your visits to the Site and other websites, alternate the ads
                    sent to a specific computer, and track how often an ad has been viewed and by whom. These cookies
                    are linked to a computer and do not gather any personal information about you.
                </p>
                <p class="std-sub-header">
                    Analytics Cookies
                </p>
                <p class="std-text">
                    Analytics cookies monitor how users reached the Site, and how they interact with and move around
                    once on the Site. These cookies let us know what features on the Site are working the best and what
                    features on the Site can be improved.
                </p>
                <p class="std-sub-header">
                    Our Cookies
                </p>
                <p class="std-text">
                    Our cookies are “first-party cookies”, and can be either permanent or temporary. These are necessary
                    cookies, without which the Site won't work properly or be able to provide certain features and
                    functionalities. Some of these may be manually disabled in your browser, but may affect the
                    functionality of the Site.
                </p>
                <p class="std-sub-header">
                    Personalization Cookies
                </p>
                <p class="std-text">
                    Personalization cookies are used to recognize repeat visitors to the Site. We use these cookies to
                    record your browsing history, the pages you have visited, and your settings and preferences each
                    time you visit the Site.
                </p>
                <p class="std-sub-header">
                    Security Cookies
                </p>
                <p class="std-text">
                    Security cookies help identify and prevent security risks. We use these cookies to authenticate
                    users and protect user data from unauthorized parties.
                </p>
                <p class="std-sub-header">
                    Site Management Cookies
                </p>
                <p class="std-text">
                    Site management cookies are used to maintain your identity or session on the Site so that you are
                    not logged off unexpectedly, and any information you enter is retained from page to page. These
                    cookies cannot be turned off individually, but you can disable all cookies in your browser.
                </p>
                <p class="std-sub-header">
                    Third-Party Cookies
                </p>
                <p class="std-text">
                    Third-party cookies may be place on your computer when you visit the Site by companies that run
                    certain services we offer. These cookies allow the third parties to gather and track certain
                    information about you. These cookies can be manually disabled in your browser.
                </p>
                <p class="std-header">CONTROL OF COOKIES</p>
                <p class="std-text">
                    Most browsers are set to accept cookies by default. However, you can remove or reject cookies in
                    your browser’s settings. Please be aware that such action could affect the availability and
                    functionality of the Site.
                </p>
                <p class="std-text">
                    For more information on how to control cookies, check your browser or device’s settings for how you
                    can control or reject cookies, or visit the following links:
                </p>
                <ul>
                    <li><a href="https://support.apple.com/kb/ph19214?locale=en_US">Apple Safari</a></li>
                    <li><a href="https://support.google.com/chrome/answer/95647?co=GENIE.Platform%3DDesktop&hl=en">Google
                        Chrome</a></li>
                    <li><a href="https://privacy.microsoft.com/en-us/windows-10-microsoft-edge-and-privacy">Microsoft
                        Edge</a></li>
                    <li><a
                        href="https://support.microsoft.com/en-gb/help/17442/windows-internet-explorer-delete-manage-cookies">Microsoft
                        Internet Explorer</a></li>
                    <li><a href="https://support.mozilla.org/en-US/kb/enable-and-disable-cookies-website-preferences">Mozilla
                        Firefox</a></li>
                    <li><a href="http://www.opera.com/help/tutorials/security/cookies/">Opera</a></li>
                    <li><a
                        href="https://support.google.com/chrome/answer/95647?co=GENIE.Platform%3DAndroid&hl=en&oco=1">Android
                        (Chrome)</a></li>
                    <li><a href="https://help.blackberry.com/en/blackberry-classic/10.3.1/help/mwa1334238823957.html">Blackberry</a>
                    </li>
                    <li><a href="https://support.google.com/chrome/answer/95647?co=GENIE.Platform%3DiOS&hl=en&oco=1">Iphone
                        or Ipad (Chrome)</a></li>
                    <li><a
                        href="https://support.google.com/chrome/answer/95647?co=GENIE.Platform%3DAndroid&hl=en&oco=1">Iphone
                        or Ipad (Safari)</a></li>
                </ul>
                <p class="std-text">
                    In addition, you may opt-out of some third-party cookies through the <a
                    href="http://optout.networkadvertising.org/#!/" target="_blank">Network Advertising Initiative’s
                    Opt-Out Tool.</a>
                </p>
                <p class="std-header">OTHER TRACKING TECHNOLOGIES</p>
                <p class="std-text">
                    In addition to cookies, we may use web beacons, pixel tags, and other tracking technologies on the
                    Site to help customize the Site and improve your experience. A “web beacon” or “pixel tag” is tiny
                    object or image embedded in a web page or email. They are used to track the number of users who have
                    visited particular pages and viewed emails, and acquire other statistical data. They collect only a
                    limited set of data, such as a cookie number, time and date of page or email view, and a description
                    of the page or email on which they reside. Web beacons and pixel tags cannot be declined. However,
                    you can limit their use by controlling the cookies that interact with them.
                </p>
                <p class="std-header">PRIVACY POLICY</p>
                <p class="std-text">
                    For more information about how we use information collected by cookies and other tracking
                    technologies, please refer to our
                    <router-link to="/privacy-policy" target="_blank">Privacy Policy</router-link>
                    on the Site. This Cookie Policy
                    is part of and is incorporated into our Privacy Policy. By using the Site, you agree to be bound by
                    this Cookie Policy and our Privacy Policy.
                </p>
                <p class="std-header">CONTACT US</p>
                <p class="std-text">
                    If you have questions or comments about this Cookie Policy, please contact us at:
                </p>
                <p class="std-text white--text">
                    One Start LLC <br>
                    2242 Zumbehl Rd, Saint Charles, MO 63301 <br>
                    info@onestartlabs.com <br>
                </p>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "CookiePolicies",

}
</script>

<style scoped>
.policy-container {
    width: 100%;
    min-height: 100%;
    background-color: #060606;
}

.std-header {
    font-weight: bold;
    font-size: 28px;
    margin-top: 35px;
}

.std-sub-header {
    font-size: 20px;
    text-decoration: underline;
    margin-top: 25px;
}

.std-text, ul li {
    font-size: 18px;
    color: #d1d1d1;
}

ul {
    margin-left: 45px;
    margin-bottom: 20px;
}

.large-header {
    color: white;
    margin-left: 15px;
    text-align: center;
    font-size: 36px;
    font-family: Roboto, serif;
}

.policy-wrapper {
    max-width: 1115px;
    margin: 0 auto;
    padding: 15px 15px 50px 15px;
}

.policy-heading {
    padding: 20px 0;
}
</style>
